import React from "react"
import sav from "../../resources/savana/sav.png"
import fb from "../../resources/icons/fb.svg"
import twitter from "../../resources/icons/twitter.svg"
import insta from "../../resources/icons/insta.svg"
import linkedin from "../../resources/icons/link.svg"

import "./Footer.css"

const Footer = () => {
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 end">
            <div className="sav">
              <img src={sav} alt="sav" />
              <br />
              <p>Hiring excellence with innovation</p>
              <span>@2020 savannaHR.</span>
              <br />
              <span>All rights reserved.</span>
            </div>
            <div className="contact">
              <span id="heading">Contact Us</span>
              <div className="email">
                <span>Email: </span>
                <p>hello@savannahr.com</p>
              </div>
              <span id="addspan">Address: </span>
              <br />
              <p id="add">
                90B, Delhi – Jaipur Expy, Sector 18 Gurugram
                <br /> Haryana 122008
              </p>
            </div>
            <div className="quicklinks">
              <span id="heading">Quick Links</span>
              <br />
              <a href="/about">
                <p>About us</p>
              </a>
              <br />
              <p>Privacy policy</p>
            </div>
            <div className="links">
              <span id="heading">Follow Us</span>
              <div>
                <a
                  href="https://www.facebook.com/savannahrservices"
                  target="_blank"
                >
                  <img src={fb} alt="fb" />
                </a>
                <a href="https://www.instagram.com/hrsavanna">
                  <img src={insta} alt="insta" />
                </a>
                <a
                  href="https://www.linkedin.com/company/savanna-hr/"
                  target="_blank"
                >
                  <img src={linkedin} alt="linkedin" />
                </a>
                <a href="https://x.com/Savanna__HR">
                  <img src={twitter} alt="twitter" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
